import tw from "twin.macro"

export const themeStyles = {
  white: tw`bg-white`,
  mist: tw`bg-brand-light_mist`,
  dark: tw`bg-brand-dark text-white`,
  black: tw`bg-deep_black text-white`
}

export const contrastText = theme => {
  if (["dark", "black"].includes(theme)) return tw`text-white`
  return tw`text-brand-black`
}
