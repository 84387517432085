import { graphql } from "gatsby"
import "twin.macro"
import * as React from "react"
import TextContent from "@molecules/text-content"
import Seo from "@atoms/seo"

const PrivacyPage = ({ data: { strapiPrivacy } }) => (
  <>
    {strapiPrivacy.seo && <Seo {...strapiPrivacy.seo} />}
    {strapiPrivacy?.text?.map?.(entry => (
      <TextContent data={entry} />
    ))}
  </>
)

export const query = graphql`
  query PrivacyPage {
    strapiPrivacy {
      name
      slug
      seo {
        ...SeoFragment
      }
      text {
        ...TextContentFragment
      }
    }
  }
`
export default PrivacyPage
